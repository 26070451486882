<template>
    <div class="problemFound">
        <header>
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="问题名称：">
                    <el-input v-model="query.problemTitle"></el-input>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-select v-model="query.problemStatus" class="search-input" placeholder="请选择">
                        <el-option key="" label="全部" value="" />
                        <el-option
                            v-for="(item, index) in problemStatusMap"
                            :key="index"
                            :label="item"
                            :value="index"
                        />
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="btns">
                <el-button @click="search" type="primary">查 询</el-button>
                <el-button @click="resize" plain>重 置</el-button>
            </div>
        </header>
        <main>
            <div class="subToolbar">
                <div class="btns">
                    <el-button v-if="userBtns.question_discovery_add" type="primary" @click="editItem(null, '问题报送')"
                        >新增</el-button
                    >
                </div>
            </div>
            <div class="table">
                <el-table
                    :data="tableData"
                    :default-sort="{ prop: 'createTime', order: 'descending' }"
                    style="width: 100%; height: 100%"
                    header-row-class-name="table-header"
                    height="calc(100% - 40px)"
                    :header-cell-style="{
                        background: '#F7F8FA',
                        color: '#333333',
                        height: '54px',
                        'font-size': '14px',
                    }"
                    @cell-dblclick="dblclickRow"
                >
                    <el-table-column type="index" width="70" label="序号" />
                    <el-table-column prop="problemTitle" label="问题名称" show-overflow-tooltip> </el-table-column>
                    <el-table-column prop="problemDescription" label="问题描述" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="limitTime" label="整改时限" width="200px">
                        <template #default="{ row }">
                            <div>{{ row.limitBeginTime }} - {{ row.limitEndTime }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="leadDeptName" label="整改牵头单位" show-overflow-tooltip> </el-table-column>
                    <el-table-column prop="problemNatureString" label="问题性质" :show-overflow-tooltip="false">
                    </el-table-column>
                    <el-table-column prop="problemStatus" label="操作状态" :show-overflow-tooltip="false">
                        <template #default="{ row }">
                            <div>
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '0'"
                                    slot="reference"
                                    >• 草稿</span
                                >
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '1'"
                                    slot="reference"
                                    >• 待分类</span
                                >
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '2'"
                                    slot="reference"
                                    >• 分类驳回</span
                                >
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '3'"
                                    slot="reference"
                                    >• 待评估</span
                                >
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '4'"
                                    slot="reference"
                                    >• 评估驳回</span
                                >
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '5'"
                                    slot="reference"
                                    >• 待审核</span
                                >
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '6'"
                                    slot="reference"
                                    >• 审核驳回</span
                                >
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '7'"
                                    slot="reference"
                                    >• 已评估</span
                                >
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '8'"
                                    slot="reference"
                                    >• 上级驳回</span
                                >
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '10'"
                                    slot="reference"
                                    >• 审核中</span
                                >
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="problemRiskLevel" label="风险等级" :show-overflow-tooltip="false">
                        <template #default="{ row }">
                            <div>
                                {{ problemRiskLevelMap[row.problemRiskLevel] }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="220">
                        <template #default="{ row }">
                            <el-button
                                v-if="
                                    (row.problemStatus === 2 || row.problemStatus === 0) &&
                                    userBtns.question_discovery_update
                                "
                                type="text"
                                size="small"
                                @click="editItem(row, '修改问题')"
                                >修改</el-button
                            >
                            <el-button type="text" size="small" @click="editItem(row, '问题报送')">查看</el-button>
                            <el-button type="text" size="small" v-if="userBtns.question_discovery_delete">
                                <el-popconfirm title="确定删除吗？" @confirm="handleDel(row)">
                                    <template #reference>
                                        <span style="color: red" slot="reference">删除</span>
                                    </template>
                                </el-popconfirm>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                        v-model:currentPage="query.page"
                        v-model:page-size="query.size"
                        :page-sizes="[15, 30, 50, 100]"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                        @size-change="getList"
                        @current-change="getList"
                    >
                    </el-pagination>
                </div>
            </div>
        </main>
    </div>
</template>
<script setup>
import { ref, reactive, computed, watch, onMounted, onUnmounted, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';

const { proxy } = getCurrentInstance();
let store = useStore();
const roles = store.state.user.roles;
function isDisables(role) {
    let res = true;
    role.forEach((e) => {
        if (roles.indexOf(e) > -1) {
            res = false;
        }
        return res;
    });
    return res;
}
const router = useRouter();
const route = useRoute();
const emit = defineEmits(['close']);
const props = defineProps({});
let query = ref({
    page: 1,
    size: 15,
    problemLevel: 0,
    problemTitle: '',
    problemVerifyType: 0,
});
let total = ref(0);
const options = [
    {
        value: null,
        label: '全部',
    },
    {
        value: 1,
        label: '已提交',
    },
    {
        value: 0,
        label: '草稿',
    },
];
let problemRiskLevelMap = {
    1: '低',
    2: '中',
    3: '高',
};

let problemStatusMap = ['草稿', '待分类', '分类驳回', '待评估', '评估驳回', '待审核', '审核驳回', '已评估', '上级驳回'];
const problemNatureMap = {
    1: '初发偶发',
    2: '多发问题',
    3: '突发问题',
    4: '历史遗留',
    5: '多跨疑难',
    6: '一般性问题',
};
let tableData = ref([]);
function fillItem(row) {
    router.push({
        path: '/fillTask',
        query: {
            title: '填报',
            type: 1,
            id: row.unitId,
        },
    });
}
function editItem(row = null, name) {
    router.push({
        name: 'problemFoundAdd',
        query: {
            title: name,
            type: 1,
            id: row ? row.problemId : null,
        },
    });
}
// 指标删除
function handleDel(row) {
    proxy.$api.problemFound.delete(row.problemId).then((res) => {
        ElMessage({
            message: '删除成功',
            type: 'success',
        });
        getList();
    });
}
function openDetail(row) {
    router.push({
        path: '/fillTask',
        query: {
            title: '查看',
            id: row.unitId,
        },
    });
}
function addQue() {}
function dblclickRow(row) {
    openDetail(row);
}
function getList() {
    // query.value.selectType = '';
    // query.value.statuses = '';
    proxy.$api.problemFound.getPage(query.value).then((res) => {
        if (res.code == 200) {
            tableData.value = res.data.records;
            total.value = res.data.total;
        }
    });
}
function search() {
    query.value.page = 1;
    getList();
}
function resize() {
    query.value = {
        page: 1,
        size: 15,
        problemLevel: 0,
        problemTitle: '',
        problemVerifyType: 0,
    };
    getList();
}

// 获取按钮权限
const userBtns = computed(() => {
    return store.state.user.userBtns;
});
watch(() => {});
onMounted(() => {
    getList();
});
onUnmounted(() => {});
</script>
<style lang="less" scoped>
.problemFound {
    height: calc(100% - 32px);
    header {
        height: 64px;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        display: flex;
        justify-content: space-between;
        padding: 16px 24px 0 24px;
        box-sizing: border-box;
        .btns {
            white-space: nowrap;
        }
    }
    main {
        margin-top: 16px;
        height: calc(100% - 85px);
        padding: 16px 24px 25px 24px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        .subToolbar {
            text-align: right;
            margin-bottom: 16px;
            .el-button {
                margin-right: 8px;
            }
        }
        .table {
            height: calc(100% - 76px);
            .table-header {
                height: calc(100% - 40px);
            }
            .pagination {
                padding-top: 10px;
                text-align: right;
            }
        }
        /deep/.el-table th.el-table__cell {
            background: #f6f8fa;
        }
    }
    .status {
        display: inline-block;
        width: 68px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        &.status2 {
            background: rgba(100, 183, 255, 0.2);
            color: #1492ff;
        }
        &.status3 {
            background: rgba(68, 203, 87, 0.2);
            color: #44cd40;
        }
        &.status1 {
            background: rgba(255, 176, 81, 0.2);
            color: #ff8b00;
        }
        &.status4 {
            background: rgba(255, 127, 127, 0.2);
            color: #ff5c5c;
        }
        &.status0 {
            background: rgba(216, 216, 216, 0.2);
            color: #999999;
        }
        &.status5 {
            background: rgba(85, 230, 193, 0.2);
            color: rgb(85, 230, 193);
        }
        &.status6 {
            background: rgba(179, 55, 113, 0.2);
            color: rgb(179, 55, 113);
        }
        &.status7 {
            background: rgba(189, 197, 129, 0.2);
            color: rgb(189, 197, 129);
        }
    }
}
</style>
